import React, { useState, useContext, useEffect } from "react";
import { Translation } from "react-i18next";
import i18next from 'i18next';
import { api_post } from "../../API.js";
import PersonContext from "../../PersonContext.js";
import { Loading } from "../Components.js";
//import { Modal } from "../Modal.js";
import { Alert } from "../Alert.js";
import { Confirm } from "../Confirm.js";

export const Agreement = (props) => {
    let context = useContext(PersonContext);
    let [isLoading, setLoading] = useState(true);
    let [requestSent, setRequestSent] = useState(false);

    useEffect(() => {
        let storageKey = 'AgreeentRequestSent' + context.person.personId.externalId;
        let requestSent = sessionStorage.getItem(storageKey);
        if (requestSent) {
            requestSent = parseInt(requestSent);
            let expires = requestSent*86400*1000;
            let now = parseInt(new Date().getTime());
            if (now < expires) {
                setRequestSent(true);
            }
        }
        setLoading(false);
    }, []); // eslint-disable-line

    const sentAgreementRequest = () => {
        
        Confirm({
            message: i18next.t('Settings.AgreementConfirm'),
            ok: i18next.t('Settings.AgreementConfirmButton'),
            onConfirm: () => { 
                setRequestSent(true);
                api_post(
                    "/persons/" +
                        context.person.personId.externalId +
                        "/payment_agreement",
                    {}
                )
                    .then((resp) => {
                        let storageKey = 'AgreeentRequestSent' + context.person.personId.externalId;
                        sessionStorage.setItem(storageKey, new Date().getTime());
                    })
                    .catch((err) => {
                        setRequestSent(false);
                        Alert("Errors.Unknown");
                    });
            }
          });

    };

    return (
        <Translation>
            {(t) =>
                isLoading ? (
                    <div className="d-flex justify-content-center mt-4 mb-4">
                        <Loading>{t("Settings.LoadingDetails")}</Loading>
                    </div>
                ) : (
                    <div className="mt-3">

                        {
                            requestSent
                            ? (
                                <p className="body-text-small mb-small">
                                    {t("Settings.AgreementPopUpText")}
                                </p>
                            )
                            : (
                                <React.Fragment>
                                    <p className="body-text-small mb-small">
                                        {t("Settings.AgreementText")}
                                    </p>

                                    <div className="text-center mb-small">
                                        <a  // eslint-disable-line
                                            className="btn secondary-btn color-secondary"
                                            onClick={() => {
                                                sentAgreementRequest();
                                            }}
                                        >
                                            {t("Settings.AgreementButton")}
                                        </a>
                                    </div>
                                </React.Fragment>
                            )
                        }
                    </div>
                )
            }
        </Translation>
    );
};
