import React, { useState, useContext, useEffect, useRef } from 'react';
import { Translation } from 'react-i18next';
import { api_get } from '../../API.js';
import PersonContext from '../../PersonContext.js';
import { Loading } from '../Components.js';
import { getWebUrl, getApiHost } from '../../config.js';
import { api_post } from '../../API.js';

export const Balance = () => {
  let context = useContext(PersonContext);
  let apiUrl = '/persons/' + context.person.personId.externalId + '/epassi/createPayment';
  let [ isLoading, setLoading ] = useState(true);

  // Epassi
  let [epassiUrl, setEpassiUrl] = useState('');
  let [epassiStamp, setEpassiStamp] = useState('');
  let [epassiSite, setEpassiSite] = useState('');
  let [epassiAmount, setEpassiAmount] = useState('');
  let [epassiVatValue, setEpassiVatValue] = useState(0);
  let [epassiMac, setEpassiMac] = useState('');
  let [prePayAmount, setTheAmount] = useState(50);

  let [subscriptions, setSubscription] = useState([]);
  let [selectedSubscription, setSelectedSubscription] = useState(0);

  const balanceForm = useRef(null);

  useEffect(() => {
    api_get('/persons/' + context.person.personId.externalId + '/prePaySubscriptions')
        .then((resp) => {
         setSubscription(resp);
         setLoading(false);
        });
  }, []); // eslint-disable-line

  function initEpassiPaybutton() {
    if(prePayAmount < 50) {
      return;
    }

    setLoading(true);
    api_post(apiUrl, {
      amount: prePayAmount,
      subscriptionId: subscriptions[selectedSubscription].subscriptionId
    })
      .then((resp) => {
        setEpassiUrl(resp.url);
        setEpassiSite(resp.site);
        setEpassiStamp(resp.stamp);
        setEpassiMac(resp.mac);
        setEpassiAmount(resp.amount);
        setEpassiVatValue(resp.vat);
        balanceForm.current.submit();
      })
      .catch((err) => {
       setLoading(false)
      });

  }

  return (
<>

<form id="epassi_payment_form" action={epassiUrl} method="post" ref={balanceForm}>
  <input type="hidden" name="STAMP" value={epassiStamp} />
  <input type="hidden" name="SITE" value={epassiSite} />
  <input type="hidden" name="AMOUNT" value={epassiAmount} />
  <input type="hidden" name="VAT_VALUE" value={epassiVatValue} />
  <input type="hidden" name="REJECT" value={getWebUrl() + '/epassi/?response=reject'} />
  <input type="hidden" name="CANCEL" value={getWebUrl() + '/epassi/?response=cancel'} />
  <input type="hidden" name="RETURN" value={getWebUrl() + '/epassi/?response=return'} />
  <input type="hidden" name="NOTIFY_URL" value={getApiHost() + '/epassiPayment/create'} />
  <input type="hidden" name="MAC" value={epassiMac} />

</form>

<Translation>
      {(t) => (
        isLoading 
        ? <div className="d-flex justify-content-center mt-4 mb-4"><Loading>{t('Settings.LoadingDetails')}</Loading></div> 
        : (
          <>

          {!epassiMac && (
            <>
           <h4 className="mt-4">Förbetala kommande månadsbetalningar med Epassi</h4>

           {!epassiMac && subscriptions.length > 0 && (
            <p className="body-text-small mb-4">Du som är ansluten till Epassi kan förbetala dina kommande månadsbetalningar med ditt friskvårdsbidrag. Fyll i valfri summa som ska användas. Beloppet måste finnas tillgängligt på ditt Epassi konto. För att se aktuellt saldo behöver du logga in i Epassi appen.</p>
          )}

           {!epassiMac && subscriptions.length < 1 && (
            <p className="body-text-small mb-4">Du har inget medlemskap som kan förlängas.</p>
          )}

           <ul className="list medium  mb-5">

              {subscriptions.map((subscription, index) => {
                return (
                  <li key={index}>
                  <div>
                      <div>
                          <span className="body-text">
                          <label className="list-radio-label">
                  <input type="radio" className="list-radio" checked={index === selectedSubscription} onChange={() => {
                    setSelectedSubscription(index)
                  }}/>
                  <div>
                  <strong>{t(subscription.cardArea)}</strong>
                  <br />
                  <div className="list-radio-description">Betalt till { subscription.billedUntilDate }</div>
                  </div>
                  </label>
                          </span>
                      </div>
                      <div className="text-end">
                          <span className="h4 mb-0">{ subscription.price } kr/mnd</span>
                      </div>
                  </div>
              </li>

                );
              })}
          </ul>

          {!epassiMac && subscriptions.length > 0 && (
          <div>
           <p className="body-text-small"><strong>Summa</strong></p>
           <input className="mb-2" type="number" value={prePayAmount} onChange={(e) => {
            setTheAmount(e.target.value)
           }}/>
           <p className="body-text-small mb-4"><i>Minsta summan att använda är 50:-</i></p>
           <div className="text-center mb-small">
            <button className="btn secondary-btn color-secondary w-auto" onClick={() => {
              initEpassiPaybutton()
            }}>Betala</button>
           </div>

           </div>
          )}
          </>
          )}

          </>
        )
      )}
    </Translation>
    </>
    );
};
