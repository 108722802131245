import React from 'react';
import { Translation } from 'react-i18next';
import i18next from 'i18next';
import { Loading, CenterName } from '../Components.js';
import { api_get, handleError } from '../../API.js';
import PersonContext from '../../PersonContext.js';
import {getDomainCountry} from "../../config.js";

export class MyActicLog extends React.Component {
  static contextType = PersonContext;

  constructor(props) {
    super();
    this.state = {
      loading: true,
      log: false,
      page: 1
    };
    this.itemsPerPage = 20;
  }

  componentDidMount() {
    document.title = i18next.t("Headline.Log") + ' - ' + i18next.t("SiteName");
    api_get('/persons/' + this.context.person.personId.externalId + '/myactic/log')
      .then((resp) => {
        this.setState({
          loading: false,
          log: Object.values(resp)
        });
      })
      .catch((resp) => {
        handleError(resp);
      });
  }

  render() {

    let maxItems = this.itemsPerPage*this.state.page;
    
    return (
      <Translation>
        {(t) => (
          <React.Fragment>
                {this.state.loading ? (<div className="d-flex justify-content-center mt-5"><Loading /></div>) : null}
                <ul className="list big">
                  {this.state.log && Array.isArray(this.state.log) ? (
                    <React.Fragment>
                      {this.state.log.map((item, index) => {
                        if (index >= maxItems) {
                          return null;
                        }
                        return (
                          <LogItem item={item} key={index} />
                        );
                      })}
                    </React.Fragment>
                  ) : null}
                </ul>
                
                {
                  this.state.log.length > maxItems
                  ? (
                    <div className="text-center mt-4 mb-small">
                      <button className="btn secondary-btn color-secondary" onClick={() => { this.setState({ page: this.state.page+1 }); }}>{t("Common.ShowMore")}</button>
                    </div>
                  )
                  : null
                }
        
          </React.Fragment>
        )}
      </Translation>
    );
  }
}

const LogItem = (props) => {
  return (
    <li>
      <div>
        <div>
          <span className="body-text-regular color-black">
            <LogTitle item={props.item} />
          </span>
          <span className="body-text-regular color-rock">
            <LogInfo item={props.item} />
          </span>
          <span className="body-text-small color-rock"><CenterName centerId={props.item.centerId} /></span>
          
        </div>
        <div className="text-end">
          
            <span className="body-text-small ms-3">
                <span className="d-block">{props.item.startDate}</span>
                <span className="d-block mb-1">{props.item.startTime}</span>
            </span>
            <ShowPoints points={props.item.points} />
        </div>
        
      </div>

    </li>
  );
}

const ShowPoints = (props) => {
  let domainCountry = getDomainCountry();

  if(domainCountry === 'SE') {
    return null;
  }

  return <><span className="h4 mb-0">{props.points}<span className="body-text ms-1">p</span></span></>
};

const LogInfo = (props) => {
  let item = props.item;
  if (typeof item.title !== 'undefined' && item.title) {
    return item.title;
  } else if (item.name === 'InviteOnce') {
    return i18next.t("MyActic.LogInviteOnce"); // 'Bjud en vän en gång';
  } else if (item.name === 'InviteWeek') {
    return i18next.t("MyActic.LogInviteWeek"); // 'Bjud en vän en vecka';
  } else if (item.name === 'InviteMonth') {
    return i18next.t("MyActic.LogInviteMonth"); // 'Bjud en vän en månad';
  } else if (item.name === 'BringFriend') {
    return i18next.t("MyActic.BringFriend"); // 'Ta med en vän';
  } else if (item.name === 'achievement') {
    return i18next.t("MyActic.Achievements"); // 'Achievements';
  } else if (item.name === 'challenge') {
    return i18next.t("MyActic.Challenge"); // 'Utmaning';
  } else if (item.name === 'swimaward') {
    return i18next.t("MyActic.SwimBandge"); // 'Simmärke';
  } else if (item.name === 'share') {
    return i18next.t("MyActic.Sharing"); // 'Delning';
  } else if (item.name === 'invite') {
    return i18next.t("MyActic.Recruitment"); // 'Värvning av medlem';
  } else if (item.name === 'invited') {
    return i18next.t("MyActic.MembershipByRecruit"); // 'Medlemskap via värvning';
  } else {
    return item.name;
  }
};

const LogTitle = (props) => {
  let item = props.item;

  if (item.type === 'subscription-start') {
    return i18next.t("MyActic.StartBonus"); // 'Startbonus för medlemskap';
  } else if (item.type === 'subscription') {
    return i18next.t("MyActic.Membership"); // 'Medlemskap';
  } else if (item.type === 'checkin') {
    return i18next.t("MyActic.Training"); // 'Träning hos Actic';
  } else if (item.type === 'participation') {
    return i18next.t("MyActic.GroupTraining"); // 'Gruppträning';
  } else if (item.type === 'clipcard' || item.type === 'startpt' || item.type === 'pt') {
    return i18next.t("MyActic.PtTraining"); // 'PT-träning';
  } else if (item.name === 'achievement') {
    return i18next.t("MyActic.Achievement"); // 'Achievement';
  } else if (item.name === 'course') {
    return i18next.t("MyActic.Course"); // 'PTgrupp';
  } else if (item.type === 'other') {
    return i18next.t("MyActic.Other"); // 'Övrigt';
  } else {
    return '';
  }
};
