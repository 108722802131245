import React, {useContext} from 'react'
import { useTranslation } from 'react-i18next';
import PersonContext from '../../PersonContext.js';
import { SmallList } from '../SmallList.js';
import { SmallListItem } from '../SmallListItem.js';
import {numberFormat} from "../../lib/numberFormat";

export const Subscription = ({subscription}) => {
  const context = useContext(PersonContext);
  const {t} = useTranslation();
    return (
        <div className="wrapper mt-4 mb-4">
            <SmallList headline={t("Subscriptions.PersonType")} className="list-small-xs">
              <SmallListItem text={t("PersonTypeLabels."+context.person.personType)}/>
            </SmallList>
            <SmallList headline={t("MemberInfo.Center")} className="list-small-xs">
              <SmallListItem text={context.center.name}/>
            </SmallList>
            <SmallList headline={t("Subscriptions.AccessLevel")} className="list-small-xs">
              <SmallListItem text={t(context.subscription.cardArea)}/>
            </SmallList>
            <SmallList headline={t("MemberInfo.Membership")} className="list-small-xs">
              {context.subscription.cardIncludes.map((feature, index) => {
                return (<SmallListItem key={index} text={feature} />);
              })}
            </SmallList>
            {
              context.subscription.product.type === 'CASH' ? null : (
                <div className="h-50-d-flex">
                  <div>
                      <span className="body-text-small">{t("ChangeSubscription.PeriodPrice")}</span>
                  </div>
                  <div className="d-flex">
                    <Price price={context.subscription.price} />
                  </div>
              </div>
              )
            }
            
            
        </div>
    )
}

const Price = ({price}) => {
  var countrycode = 'NO';

  if (countrycode === 'NO') {
      return <span><span className="h4">{Math.round(price)}</span><span className="body-text">kr/mnd</span></span>;
  } else if (countrycode === 'DE' || countrycode === 'FI') {
      return <span><span className="h4">{numberFormat(price, 2, '.', '')}</span><span className="body-text">&euro;/Mon</span></span>;
  } else { // SE
      return <span><span className="h4">{Math.round(price)}</span><span className="body-text">kr/mån</span></span>;
  }
}