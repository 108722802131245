import React, { useState, useContext, useEffect } from 'react';
import {getApiHost, getDomainCountry} from '../../config.js'
import { Translation } from 'react-i18next';
import PersonContext from "../../PersonContext.js";
//import i18next from 'i18next';
import { api_get, handleError } from '../../API.js';
import { Dropdown } from "../Dropdown.js";
//import { Loading } from '../Components.js';
//import { Alert } from '../Alert.js';

export const Contribution = (props) => {
  let context = useContext(PersonContext);
  //let [ isLoading, setLoading ] = useState(true);
  let [closeDropdown, setCloseDropdown] = useState(0);
  let [isSubmitting, setSubmitting] = useState(false); // eslint-disable-line
  let [isInvalid, setInvalid] = useState(false);
  let [payingFor, setPayingFor] = useState(false);
  let [data, setData] = useState(false);

  useEffect(() => {

    let end;
    let start;

    if (new Date().getMonth() > 5) {
      // At the end of the year
      start = new Date(new Date().getFullYear(), 0, 1, 12);
      end = new Date();
    } else {
      // new Date().get
      start = new Date(new Date().getFullYear(), 0, 1, 12);
      end = new Date();
      //start = new Date();
      //start.setMonth(start.getMonth() - 3);
    }
    
    
    setData({
      receiptMember: context.person,
      startDate: start.toISOString().split('T')[0],
      endDate: end.toISOString().split('T')[0],
    });
    
    api_get(
        "/persons/" + context.person.personId.externalId + "/payingfor"
    ).then((resp) => {
      if (resp.payingFor) {
        setPayingFor(resp.payingFor);
      }
    });

  }, []); // eslint-disable-line

  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value});
  }

  const handleSubmit = () => {

    let invalid = {};
    for (let keyName of Object.keys(data)) {
      if (typeof data[keyName] === 'undefined' || data[keyName] === '') {
        invalid[keyName] = true;
      }
    }

    if (Object.keys(invalid).length) {
      setInvalid(invalid);
      return false;
    } else {
      setInvalid(false);
    }

    let url = getApiHost() + '/persons/'+context.person.personId.externalId+'/contribution/'+data.startDate+'/'+data.endDate
    //if (payingFor) {
      url += '/' + data.receiptMember.personId.center + 'p' + data.receiptMember.personId.id;
    //}
    url += "?AccessToken="+context.accessToken+"&DomainCountry="+getDomainCountry();
    window.open(
      url,
      '_blank'
    );

    /*
    setSubmitting(true);
    api_patch("/persons/"+context.person.personId.externalId+"/details", data)
      .then(resp => {
        context.setPersonData({
          person: resp.person,
          personCommunication: resp.personCommunication,
        });
        setSubmitting(false);
        Alert(i18next.t('Settings.DetailsUpdateSuccess'));
      })
      .catch(err => {
        handleError(err);
        setSubmitting(false);
      })
      */
    
  }

  return (
    <Translation>
      {(t) => (
        <div className="mt-4">
          <p className="body-text-small">{t("Settings.ContributionInfo1")}</p>
          <p className="body-text-small">{t("Settings.ContributionInfo2")}</p>
          <p className="body-text-small">{t("Settings.ContributionInfo3")}</p>
          <div className="row">
            <div className="col col-md-6">
              <div className="input-group">
                <input
                    type="date"
                    className={isInvalid.startDate ? 'invalid' : ''}
                    name="startDate"
                    value={data.startDate}
                    disabled={isSubmitting ? true : false}
                    onChange={handleChange}
                    placeholder={t('Settings.ContributionStartDate')}
                  />
                </div>
            </div>
            <div className="col col-md-6">
              <div className="input-group">
                <input
                    type="date"
                    className={isInvalid.endDate ? 'invalid' : ''}
                    name="endDate"
                    value={data.endDate}
                    disabled={isSubmitting ? true : false}
                    onChange={handleChange}
                    placeholder={t('Settings.ContributionEndDate')}
                  />
              </div>
            </div>
          </div>

          {
            data.receiptMember 
            ? (
              <div className="row">
                <div className="col col-md-12">
                  <div className="input-group">
                  <Dropdown
                      closeDropdown={closeDropdown}
                      label={
                        data.receiptMember ? data.receiptMember.firstName + " " + data.receiptMember.lastName : ''}
                    >
                        <ul>
                          <li
                            className={
                              data.receiptMember.personId.externalId  === context.person.personId.externalId
                                ? "selected"
                                : ""
                            }
                            onClick={() => {
                              setData({...data, receiptMember: context.person});
                              setCloseDropdown(closeDropdown+1);
                            }}
                          ><span>{context.person.firstName + " " + context.person.lastName + " ("+ context.person.personId.center+"p"+ context.person.personId.id+")"}</span></li>

                          {
                            payingFor 
                            ? (
                              payingFor.map((payingForPerson) => {
                                return <li
                                key={payingForPerson.personId.externalId}
                                  className={
                                    data.receiptMember.personId.externalId  === payingForPerson.personId.externalId
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() => {
                                    setData({...data, receiptMember: payingForPerson});
                                    setCloseDropdown(closeDropdown+1);
                                  }}
                                ><span>{
                                  payingForPerson.firstName + " " + payingForPerson.lastName + " ("+ payingForPerson.personId.center+"p"+ payingForPerson.personId.id+")"
                                }</span></li>;
                              })
                              
                            ) : null
                            }
                        </ul>
                    </Dropdown>
                  </div>
                </div>
              </div>
            )
            : null
          }
          

          <div className="col-12 mt-small mb-small">
              <div className="text-center">
                  <button 
                  className="btn secondary-btn color-secondary w-auto" 
                  onClick={handleSubmit}
                  disabled={isSubmitting ? true : false}
                  >{t("Settings.ContributionFetch")}</button>
              </div>
          </div>

        </div>
      )}
    </Translation>
    );
};
