import React, { useState, useContext, useEffect } from "react";
import { getDomainCountry } from "./config.js";
import { useParams } from "react-router-dom";
import { Translation } from "react-i18next";
import i18next from "i18next";
import { NavTop, NavBar } from "./components/Nav.js";
import { TopContent } from "./components/TopContent.js";
import { Section, Container, OfferCarousel } from "./components/Components.js";
import { Confirm } from "./components/Confirm.js";
import PersonContext from "./PersonContext.js";
import { Subscription } from "./components/settings/Subscription.js";
import { Autogiro } from "./components/settings/Autogiro.js";
import { StopSubscription } from "./components/settings/StopSubscription.js";
import { Contracts } from "./components/settings/Contracts.js";
import { Contribution } from "./components/settings/Contribution.js";
import { Details } from "./components/settings/Details.js";
import { Freeze } from "./components/settings/Freeze.js";
import { Invoices } from "./components/settings/Invoices.js";
import { Password } from "./components/settings/Password.js";
import { Agreement } from "./components/settings/Agreement.js";
import { PT } from "./components/settings/PT.js";
import { Courses } from "./components/settings/Courses.js";
import { TextBold } from "./components/TextBold.js";
import { Button } from "./components/Button.js";
import { FreezeNo } from "./components/settings/FreezeNo.js";
import { CommingCourses } from "./components/settings/CommingCourses.js";
import { Balance } from "./components/settings/Balance.js";
import { MyActicBenefits } from './components/myactic/MyActicBenefits.js';
import { Loading } from "./components/Components.js";
import { api_get } from './API.js';

export const PageSettings = (props) => {
  let domainCountry = getDomainCountry();
  let freezeComponent = "";
  let memberURL = "https://www.actic.se/bli-medlem/";
  if (domainCountry === "SE") {
    freezeComponent = <Freeze />;
  } else if (domainCountry === "NO") {
    freezeComponent = <Freeze/>;
  } else if (domainCountry === "DE") {
    freezeComponent = <Freeze />;
  }

  let context = useContext(PersonContext);
  let personContext = useContext(PersonContext);
  let params = useParams();
  let [loading, setLoading] = useState(true);
  let [disableSettings, setDisableSettings] = useState([]);
  let [personSettings, setPersonSettings] = useState([]);
  let [loadingMyActic, setLoadingMyActic] = useState(true);
  let [errors, setErrors] = useState(false);
  let hideEpassi = false;
  useEffect(() => {
    document.title = i18next.t("Headline.Settings") + " - " + i18next.t("SiteName");
    if (personContext.myactic) {
      setLoadingMyActic(false);
    } else {
      getMyActic();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
      api_get('/persons/' + context.person.personId.externalId + '/settings')
      .then((resp) => {
        if(resp.success) {
          setDisableSettings(resp.disableSettings);
          setPersonSettings(resp.personSettings);
        } else {
          setErrors(true);
        }
        setLoading(false);
      });
  }, []); // eslint-disable-line

  const getMyActic = () => {
    personContext
      .getMyActic()
      .then((loadingDone) => {
        // done
        if (loadingDone) {
          setLoadingMyActic(false);
        } else {
          setTimeout(() => {
            getMyActic();
          }, 2000);
        }
      })
      .catch(() => {
        // error
        Confirm({
          message: i18next.t("Errors.FetchMyActicErrorRetry"),
          onConfirm: () => {
            setLoadingMyActic(true);
            getMyActic();
          },
        });
        setLoadingMyActic(false);
      });
  };

    let hasSubscription = personContext.subscription ? true : false;
    let canSeeInvoices = true;

    // Temp för Västerås - City
    let canCancelSubscription = false;
    if(personContext.center.centerId !== 179) {
      canCancelSubscription = true;
    }

    //let memberId = personContext.person.personId.center + 'p' + personContext.person.personId.id;
    let canSeeChangeSection = false;
    if (personContext.center.countrycode === "SE"
        || personContext.center.countrycode === "NO") {
      canSeeChangeSection = true;
    }
    /*
    [
      '535p96410',
      '539p70403',
      '522p100468',
      '525p15866',
      '541p83811'
    ];
    */

    function isSettingDisabledForCenter($key) {
      if(disableSettings === null || disableSettings === undefined) {
        return false;
      }
      return disableSettings.includes($key);
    }

    function havePersonalSetting($key) {
      if(personSettings === null || personSettings === undefined) {
        return false;
      }
      return personSettings.includes($key);
    }

    return (
      <Translation>
        {(t) => (
          <React.Fragment>
            <NavTop />
            {!loading && havePersonalSetting('haveK5Campaign') && (
                <OfferCarousel onClick={() => { context.openModal("recruit-cash"); }} title={t("Recruit._version._cash.CarouselButton")} />
            )}

            {!loading && havePersonalSetting('inviteFriendAllowed') && (
                <OfferCarousel onClick={() => { context.openModal("recruit-gt"); }} title={t("Recruit._version._gt.CarouselButton")} />
            )}
            {!loading && !havePersonalSetting('inviteFriendAllowed') && !havePersonalSetting('haveK5Campaign') && (
                <OfferCarousel onClick={() => { context.openModal("recruit"); }} title={t("Headline.Recruit")} />
            )}
            
            <TopContent
              h1={t("Headline.Membership")}
              h2={t("Headline.MyPages")}
            />

            { loading ? (
            <div className="container d-flex justify-content-center">
              <Loading />
            </div>
            ) : null }

            { !loading && errors ? (
              <div className="container d-flex justify-content-center">Någonting gick fel, försök igen.</div>
            ) : null }

            { !loading && !errors ? (
            <div className="wrapper-settings">

              {/* Underhållsmedelande */}
              { personContext.maintenanceMessage ? (
                <Section headline={t("Settings.MessageHeadline")} open={true}>
                  <Container>
                    <div className="mb-small mt-3">
                      <p className="text-large">{personContext.maintenanceMessage}</p>
                    </div>
                  </Container>
                </Section>
              ) : null }

              {/* Medlemsförmåner */}
              { getDomainCountry() === 'SE' && !isSettingDisabledForCenter('membership_benefits') ? (
                <Section open={false} headline="Medlemsförmåner">
                  <Container>
                    <MyActicBenefits loadingMyActic={loadingMyActic} />
                  </Container>
                </Section>
              ) : null }

              {/* Kurser med förtur */}
              { getDomainCountry() === 'SE' && !isSettingDisabledForCenter('courses_with_pre-requisites') ? (
                <Section headline="Kurser med förtur">
                  <Container>
                    <CommingCourses />
                  </Container>
                </Section>
              ) : null }

              {/* Epassi */}
              { getDomainCountry() === 'SE' && hideEpassi === false && !isSettingDisabledForCenter('epassi') ? (
                <Section headline="EPASSI">
                  <Container>
                    <Balance />
                  </Container>
                </Section>
              ) : null }

              {/* Mina uppgifter */}
              { !isSettingDisabledForCenter('my_details') ? (
                <Section headline={t("Settings.DetailsHeadline")}>
                  <Container>
                    <Details />
                  </Container>
              </Section>
              ) : null }

              {/* Mitt medlemskap */}
              {canSeeChangeSection && !isSettingDisabledForCenter('my_membership') ? (
                <Section headline={t("Settings.SubscriptionHeadline")}>
                  <Container>
                    {hasSubscription ? (
                      <React.Fragment>
                        <Subscription />
                        {personContext.newSubscription ? (
                          personContext.newSubscription.startDate ===
                          new Date().toISOString().split("T")[0] ? (
                            <TextBold
                              text={t(
                                "ChangeSubscription.SubscriptionChangedInfo"
                              )}
                            />
                          ) : (
                            <TextBold
                              text={t(
                                "ChangeSubscription.SubscriptionChangedInfoDate",
                                {
                                  startDate:
                                    personContext.newSubscription.startDate,
                                }
                              )}
                            />
                          )
                        ) : personContext.subscription.product.type ===
                          "CASH" ? null : (
                          <div className="text-center mb-small">
                            <Button
                              action="/medlemskap/andra"
                              text={t("ChangeSubscription.Change")}
                              className="btn secondary-btn color-secondary"
                            />
                          </div>
                        )}
                      </React.Fragment>
                    ) : (
                      <div className="d-flex justify-content-center mt-4 mb-4">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: t("Settings.HasNoSubscription", {
                              url: memberURL,
                            }),
                          }}
                        ></p>
                      </div>
                    )}
                  </Container>
                </Section>
              ) : null}

              {/* Ändra lösenord */}
              { !isSettingDisabledForCenter('change_password') ? (
                <Section headline={t("Settings.PasswordHeadline")}>
                  <Container>
                    <Password />
                  </Container>
                </Section>
              ) : null}

              {/* Autogiro */}
              { !isSettingDisabledForCenter('autogiro') ? (
                <React.Fragment>
                  <Section
                    headline={t("Settings.AutogiroHeadline")}
                    open={
                      params.section === "autogiro" ||
                      params.section === "avtalegiro"
                        ? true
                        : false
                    }
                  >
                    <Container>
                      <Autogiro {...props} />
                    </Container>
                  </Section>
                </React.Fragment>
              ) : null}

              {/* Mina Betalningar */}
              {canSeeInvoices && !isSettingDisabledForCenter('my_payments') ? (
                <Section headline={t("Settings.InvoicesHeadline")}>
                  <Container>
                    <Invoices />
                  </Container>
                </Section>
              ) : null}

              {/* Ändra Kortuppgifter */}
              { !isSettingDisabledForCenter('change_card_details') ? (
                <Section headline={t("Settings.AgreementHeadline")}>
                  <Container>
                    <Agreement />
                  </Container>
                </Section>
              ) : null}

              {/* Mina avtal */}
              {hasSubscription && !isSettingDisabledForCenter('my_agreements') ? (
                <React.Fragment>
                  <Section headline={t("Settings.ContractsHeadline")}>
                    <Container>
                      <Contracts />
                    </Container>
                  </Section>
                </React.Fragment>
              ) : null}

              {/* Friskvårdskvitto */}
              {canSeeInvoices && !isSettingDisabledForCenter('wellness_receipt') ? (
                <Section  className={domainCountry === "NO" ? "d-none container" : null } headline={t("Settings.ContributionHeadline")}>
                  <Container>
                    <Contribution />
                  </Container>
                </Section>
              ) : null}

              {/* Kurser */}
              { !isSettingDisabledForCenter('courses') ? (
              <Section headline="Kurser">
                <Container>
                  <Courses />
                </Container>
              </Section>
              ) : null}

              {/* Klippkort */}
              { !isSettingDisabledForCenter('clipcards') ? (
                <Section headline={t("Settings.ClipcardHeadline")}>
                  <Container>
                    <PT />
                  </Container>
                </Section>
              ) : null}


              {/* Frysa medlemskap && Uppsägning av medlemskap*/}
              {hasSubscription ? (
                <React.Fragment>

                  {/* Frysa medlemskap */}
                  {!isSettingDisabledForCenter('freeze_membership') ? (
                  <Section
                    headline={t("Settings.FreezeHeadline")}
                    open={
                        params.section === "frysa-medlemskap" ||
                        params.section === "fryse-medlemskap"
                            ? true
                            : false
                    }
                    >
                    <Container>
                      {freezeComponent}
                    </Container>
                  </Section>
                  ) : null}

                  {/* Uppsägning av medlemskap */}
                  {canCancelSubscription && !isSettingDisabledForCenter('membership_cancellation') ? (
                  <Section headline={t("Settings.CancelHeadline")}>
                    <Container>
                      <StopSubscription />
                    </Container>
                  </Section>
                  ) : null}
                </React.Fragment>
              ) : null}
            </div>
            ) : null }
            <NavBar />
          </React.Fragment>
        )}

      </Translation>
    );
}
