import React, {useState, useEffect} from "react";
import{ Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18next from 'i18next';
import { NavTop } from "./components/Nav.js";
import { LoginForm } from "./components/login/LoginForm.js";
import { Section, Container } from "./components/Components.js";
import { api_get, handleError } from './API.js';

export const PageLogin = (props) => {

  let [maintenanceMessage, setMaintenanceMessage] = useState(''); // eslint-disable-line

  useEffect(() => {
    document.title = i18next.t("Headline.Login") + ' - ' + i18next.t("SiteName");

    api_get('/status')
      .then((resp) => {
        if (resp.maintenance && resp.maintenanceMessage) {
          setMaintenanceMessage(resp.maintenanceMessage);
        }
      })
      .catch((resp) => {
        handleError(resp);
      });

  }, []); // eslint-disable-line

  return (
    <Translation>
      {(t) => (
        <React.Fragment>
          <NavTop />
       
          <div className="container wrapper-login">
            <div className="row justify-content-center">
              <div className="col col-md-6">
                  <p className="h5 m-0">{t("Login.PreHeadline")}</p>
                  <h1>{t("Login.Headline")}</h1>

                  {
                    maintenanceMessage
                    ? (
                          <div className="mb-4 mt-3">
                            <h5 className="mb-0">{t("Settings.MessageHeadline")}</h5>
                            <p className="text-large" style={{ color: '#B21350' }} dangerouslySetInnerHTML={{ __html: maintenanceMessage.replaceAll("\n", "<br>")}}></p>
                          </div>
                    )
                    : null
                  }
                  <LoginForm handleSignInSuccess={props.handleSignInSuccess} />
                  <div className="text-center mt-5"><Link className="body-text-small color-primary link" to="/glomt-losenord">{t("Recover.LinkTo")}</Link></div>
              </div>
            </div>
          </div>
        </React.Fragment>
        
      )}
    </Translation>
  );

}
