import React, { useState, useContext, useEffect } from 'react';
import { Translation } from 'react-i18next';
import i18next from 'i18next';
import { api_get } from '../../API.js';
import PersonContext from '../../PersonContext.js';
import { Loading } from '../Components.js';
import { getWebUrl } from '../../config.js';
//import { Alert } from '../Alert.js';

export const CommingCourses = (props) => {
  let context = useContext(PersonContext);
  let [ courses, setCourses ] = useState([]);
  let [ isLoading, setLoading ] = useState(true);

  useEffect(() => {
    api_get('/persons/' + context.person.personId.externalId + '/courses/comming')
        .then((resp) => {
          setCourses(resp.courses);
          setLoading(false);
        });
  }, []); // eslint-disable-line

  return (
    <Translation>
      {(t) => (
        isLoading 
        ? <div className="d-flex justify-content-center mt-4 mb-4"><Loading>{t('Settings.LoadingDetails')}</Loading></div> 
        : (
          <>
            { courses.length > 0 && (
            <ul className="list">
                {
                courses.map((item, index) => {
                    return <CourseItem item={item} key={index} />;
                })
                }
            </ul>
            )}
            { courses.length === 0 && (
                <div className="mt-3">
                <p>Inga kommande kurser</p>
                </div>
            )}
          </>
        )
      )}
    </Translation>
    );
};

const CourseItem = (props) => { 
    let item = props.item;
    let weburl = getWebUrl();
    return (
    <li className="course-item-holder mb-40">
        <div>
          <div className="course-item">
            <h4>{item.name}</h4>
            <div className="info-row">
                <div>
                    <span className="caption-text color-rock">{i18next.t("Settings.CoursePeriod")}</span>
                </div>
                <div>
                    <span className="caption-text color-rock">{item.meta.course_startdate} - {item.meta.course_stopdate}</span>
                </div>
            </div>
            <div className="info-row">
                <div>
                    <span className="caption-text color-rock">{i18next.t("Settings.CourseNumberOfTimes")}</span>
                </div>
                <div>
                    <span className="caption-text color-rock">{item.meta.number_occassions} st</span>
                </div>
            </div>
            <div className="info-row">
                <div>
                    <span className="caption-text color-rock">{i18next.t("Settings.CourseLeader")}</span>
                </div>
                <div>
                    <span className="caption-text color-rock">{item.meta.course_instructors}</span>
                </div>
            </div>
            <div className="info-row mb-4">
                <div>
                    <span className="caption-text color-rock">{i18next.t("Settings.CourseCenter")}</span>
                </div>
                <div>
                    <span className="caption-text color-rock"><a target="_blank" href={weburl.substring(0, weburl.length - 2) + item.center.address_country.toLowerCase() + item.center.url}>{item.center.webName}</a></span>
                </div>
            </div>

            <div className="info-row">
                <div>
                    <span className="caption-text color-rock">{i18next.t("Settings.CourseTimes")}</span>
                </div>
                <div>
                    <span className="caption-text color-rock">{i18next.t('DayNamesPlural.' + item.meta.course_weekdays)} {item.meta.course_timeintervals}</span>
                </div>
            </div>

            <div className="info-row">
                <div>
                    <span className="caption-text color-rock"></span>
                </div>
                <div>
                    <span className="caption-text color-rock pre-line">{item.meta.course_description}</span>
                </div>
            </div>

            <div className="info-row">
                <div>
                    <span className="caption-text color-rock"></span>
                </div>
                <div>
                    <span className="caption-text color-rock"><a href={getWebUrl() + '/' + item.link} target="_blank" className="btn secondary-btn color-secondary">Boka</a></span>
                </div>
            </div>
        </div>
        </div>
     </li>
    );
  }