import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Translation } from "react-i18next";
//import i18next from "i18next";
import PersonContext from "../PersonContext.js";
import {getSupportURL, getDomainCountry} from "../config.js";
import { ReactComponent as NavLogo } from "../assets/icons/logo-black.svg";

const NavLinks = () => {
  let context = useContext(PersonContext);

  return (
    <Translation>
      {(t) => (
        <React.Fragment>
          {
            context.displayMyActicNavItem
            ? (
              <Link to="/myactic" className={"barmenu-myactic" + (window.location.pathname === '/myactic' || window.location.pathname === '/aktivitetslogg' ? ' active' : '')}>
              <span>{t("Menu.MyActic")}</span>
            </Link>
            )
            : null
          }
          

          {
            context.displayScheduleNavItem
            ? (
              <Link to="/boka-pass" className={"barmenu-booking" + (window.location.pathname === '/boka-pass' ? ' active' : '')}>
                <span>{t('Menu.Booking')}</span>
              </Link>
            )
            : (
              <div className="barmenu-booking inactive">
                <span>{t('Menu.Booking')}</span>
              </div>
            )
          }
          
          
          <Link to="/statistik" className={"barmenu-statistics" + (window.location.pathname === '/statistik' ? ' active' : '')}>
            <span>{t('Menu.Statistics')}</span>
          </Link>
          
          <Link to="/medlemskap" className={"barmenu-membership" + (window.location.pathname === '/medlemskap' ? ' active' : '')}>
            <span>{t('Menu.Membership')}</span>
          </Link>

          <a href={getSupportURL()} target="_blank" rel="noreferrer" className="barmenu-support">
            <span className="d-none d-xxl-block" dangerouslySetInnerHTML={{__html: t('Menu.SupportDesktop')}}></span>
            <span className="d-block d-xxl-none" dangerouslySetInnerHTML={{__html: t('Menu.SupportMobile')}}></span>
          </a>
        </React.Fragment>
      )}
    </Translation>
  );
  
}

export const NavBar = (props) => {

  const search = useLocation().search;
  const nomenu = new URLSearchParams(search).get("nomenu");
  if (typeof nomenu !== 'undefined' && nomenu) {
    return null;
  }
  return (
    <React.Fragment>
      <footer></footer>
      <div className="barmenu">
        <NavLinks />
      </div>
    </React.Fragment>
  );
}

export const MenuItem = (props) => {
  return (
    <li className="menu-item menu-item-type-custom menu-item-object-custom">
      {props.children}
    </li>
  );
}


export const NavTop = (props) => {
  let context = useContext(PersonContext);
  let domainCountry = getDomainCountry();
  let [isOpen, setIsOpen] = useState(false);

  const search = useLocation().search;
  const nomenu = new URLSearchParams(search).get("nomenu");
  if (typeof nomenu !== 'undefined' && nomenu) {
    return null;
  }

  return (
    <Translation>
      {(t) => (
        <header>
          <div className="wrapper-nav">
            <div className="container">
              <div className="row justify-content-between align-items-center">

                <div className="col offset-4 offset-md-0 text-center text-md-start">
                  <Link to={context.loggedIn ? "/medlemskap" : "/"}>
                    <NavLogo className="logo" width={null} height={null} />
                  </Link>
                </div>

                <div className="col-md-8 col-lg-8 col-xxl-7 mx-auto order-md-2 d-none d-lg-block">
                    <div className="topmenu d-flex align-items-center">
                    {context.loggedIn ? (
                      <NavLinks />
                    ) : null}
                  </div>
                </div>

                
                <div className="col ml-auto order-md-3">
                  <div className="d-flex justify-content-end align-items-center">
                  {context.loggedIn ? (
                    <div className={"nav-icon" + (isOpen ? " open" : "") } onClick={() => { 
                      if (isOpen) {
                        setIsOpen(false);
                      } else {
                        setIsOpen(true);
                      }
                    }}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  ) : null
                  }
                    
                  </div>
                </div>
                
                {context.loggedIn ? (
                <nav className={
                  "mobile-nav p-0"
                  + (isOpen ? " menu-open" : "")
                  }>
                  <div className="menu-header-menu-container">
                    <ul id="menu-header-menu" className="menu">
                      {
                        domainCountry === 'SE'
                        ? (
                          <MenuItem>
                            <a href="https://www.actic.se" target="_blank" rel="noreferrer">Actic.se</a>
                          </MenuItem>
                        )
                        : null
                      }
                      {
                        domainCountry === 'NO'
                        ? (
                          <MenuItem>
                            <a href="https://www.actic.no" target="_blank" rel="noreferrer">Actic.no</a>
                          </MenuItem>
                        )
                        : null
                      }
                      {
                        domainCountry === 'DE'
                        ? (
                          <MenuItem>
                            <a href="https://www.acticfitness.de" target="_blank" rel="noreferrer">Acticfitness.de</a>
                          </MenuItem>
                        )
                        : null
                      }

                      {
                        context.displayMyActicNavItem
                        ? (
                          <MenuItem>
                            <Link to="/myactic">
                            {t('Menu.MyActic')}
                            </Link>
                          </MenuItem>
                        )
                        : null
                      }
                      

                      <MenuItem>
                        <Link to="/statistik">{t('Menu.Statistics')}</Link>
                      </MenuItem>

                      {
                        context.displayMyActicNavItem
                        ? (
                          <MenuItem>
                            <Link to="/aktivitetslogg">{t('Menu.Log')}</Link>
                          </MenuItem>
                          )
                        : null
                      }
                      

                      <MenuItem>
                        <Link to="/medlemskap">
                        {t('Menu.Membership')}
                        </Link>
                      </MenuItem>
{/* 
                      <MenuItem>
                        <a href="#" onClick={(e) => { e.preventDefault(); context.openModal("welcome") }}>
                        Om Mina sidor
                        </a>
                      </MenuItem>

                      <MenuItem>
                        <a href="#" onClick={(e) => { e.preventDefault(); context.openModal("myactic") }}>
                        Om MyActic
                        </a>
                      </MenuItem>  
*/}

                      <MenuItem>
                        <a href="/" onClick={context.singOut}>
                        {t('Menu.Logout')}
                        </a>
                      </MenuItem>
                    </ul>
                  </div>
                </nav>
                ) : null}
              </div>
            </div>
          </div>
        </header>
        
      )}
    </Translation>
  );
};
