import React from 'react';

export const TopContent = (props) => {
  return (
    <section className="top-content">
        <div className="container">
            <div className="row justify-content-center">
                <div className={"col-12 col-md-10 col-xxl-10 " + (props.className ? props.className : null)}>
                  <h1>{props.h1}</h1>
                  {
                    typeof props.h2 !== 'undefined' && props.h2
                    ? <h2>{props.h2}</h2>
                    : null
                  }

                  {
                    typeof props.p !== 'undefined' && props.p
                    ? <p className="body-text">{props.p}</p>
                    : null
                  }
                </div>
            </div>
        </div>
    </section>
  );
};
