import React, { useState, useContext, useEffect } from 'react';
import { Translation } from 'react-i18next';
import i18next from 'i18next';
import { api_get } from '../../API.js';
import PersonContext from '../../PersonContext.js';
import { Loading } from '../Components.js';
//import { Alert } from '../Alert.js';

export const PT = (props) => {
  let context = useContext(PersonContext);
  let [ clipcards, setClipcards ] = useState([]);
  let [ subscriptions, setSubscriptions ] = useState([]);
  let [ isLoading, setLoading ] = useState(true);

  useEffect(() => {
    api_get('/persons/' + context.person.personId.externalId + '/pt')
        .then((resp) => {
          setClipcards(resp.clipcards);
          setSubscriptions(resp.subscriptions);
          setLoading(false);
        });
  }, []); // eslint-disable-line

  return (
    <Translation>
      {(t) => (
        isLoading 
        ? <div className="d-flex justify-content-center mt-4 mb-4"><Loading>{t('Settings.LoadingDetails')}</Loading></div> 
        : (
          <React.Fragment>
          <ul className="list">
            {
              clipcards.map((item, index) => {
                return <ListItemClicards item={item} key={index} />;
              })
            }
            {
              subscriptions.map((item, index) => {
                return <ListItemSubscriptions item={item} key={index} />;
              })
            }
          </ul>
          {
              !clipcards.length && !subscriptions.length
              ? (<div className="mt-4 mb-4"><p className="body-text-small">{t("Settings.ClipcardsNoFound")}</p></div>)
              : null
            }
          </React.Fragment>
        )
      )}
    </Translation>
    );
};

const ListItemClicards = (props) => { 
  let item = props.item;
  return (
    <li>
      <div>
        <div>
          <span className="body-text color-black">{item.clipcardTypeName}</span>
        </div>
        <div className="text-end">
          <span className="body-text">{i18next.t("Settings.ClipcardsHoursLeft")}: {item.clipsLeft} / {item.clipsInitial}</span>
          <span className="body-text-small">{i18next.t("Settings.ClipcardsValidTo")}: {item.validUntilDate}</span>
        </div>
      </div>
    </li>
  );
}

const ListItemSubscriptions = (props) => { 
  let item = props.item;
  return (
    <li>
      <div>
        <div>
          <span className="body-text color-black">{item.product.name}</span>
        </div>
        <div className="text-end">
          <span className="body-text">{i18next.t("Settings.ClipcardsInvoiceTo")}: {item.billedUntilDate}</span>
          <span className="body-text-small">{i18next.t("Settings.ClipcardsBindingTo")}: {item.bindingEndDate}</span>
        </div>
      </div>
    </li>
  );
}